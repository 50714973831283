import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import Header from "../../components/sections/header"
import Footer from "../../components/sections/footer"
import GetStarted from "../../components/sections/getstarted"

const WhyStaiPage = () => (
  <Layout>
    <SEO title="Features" />
    <Navigation />
    <Header />
    <GetStarted />
    <Footer />
  </Layout>
)

export default WhyStaiPage
